module.exports = {
  appEnv:"local", //['local', 'development', 'production']
  backendUrl: "https://portal.getengagingstories.com/api/",
  baseUrl: "https://portal.getengagingstories.com",
  extensionID : "fneammoociaifaichlpndeddpchakkjh",
  name : "Engaging Stories",
  shortName : "ES",


  // backendUrl: "http://localhost:8000/api/",
  // baseUrl: "http://localhost:3000",
  // extensionID  : "jcabnpjennifbocjpodbhijmoaaoffdm"


};
